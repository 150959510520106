<template>
	<div class="list-lista">
		<div class="list-listba">
			<!-- 总背景颜色 -->
			<div class="list-img">
				<!-- 头部背景图图片 -->
				<img :src="'http://img4.yiqifei.com/Countries/'+CountryCode+'.jpg'" class="list-imga">
			</div>
			<div class="list-sousuo">
				<div class="list-sousuoa">
					<el-autocomplete style="width:100%" class="inline-input"
						v-model="DestinationCountryVal" :fetch-suggestions="queryCitySearch"
						placeholder="输入要办理签证的国家或地区名称" :trigger-on-focus="false"
						@select="handleSelectCity"></el-autocomplete>
					<!-- <el-input v-model="input" placeholder="请输入内容"></el-input> -->
				</div>
				<!-- <div class="list-sousuob" @click="bindSearch">快速搜索</div> -->
			</div>
			<div class="list-listrow">
				<!-- 列表 -->
				<div class="list-listrowa">
					<!-- 左边列表总框 -->
					<!-- <div class="list-listrowab">一起飞>签证>{{CountryName}}</div> -->
					<div class="list-listrowb marg-but" v-for="(item,index) in VisaList" :key="index">
						<div class="list-listrowc">
							<div class="list-imgb">
								<!-- 左列表图片 -->
								<img :src="item.VisaTypeImg" class="list-imga">
							</div>
							<div class="">
								<div class="list-listrowmg" style="font-size: 18px;">{{item.VisaName}}</div>
								<!-- 美国旅游签证多次 -->
								<div class="">
									<div class="list-listrowm">有效期：{{item.Validday}}</div>
									<div class="list-listrowma">最多停留：{{item.Stay}}</div>
									<div class="list-listrowma">预计工作日：{{item.NeedDay}}</div>
								</div>
							</div>
						</div>

						<div class="list-price" style="padding-right: 50px;">
							<div class="list-pricea">
								<div class="list-pricee">
									<div class="list-priceaa">¥</div>
									<div class="list-priceab">{{item.MinPrice}}</div>
								</div>
								<p v-if="item.IncFees!=''" style="color: #666;">({{item.IncFees}})</p>
							</div>
							<div class="list-priceb" @click="bindJumpDetail(item)">
								<div class="" style="padding: 3px; font-size: 18px;" >预定</div>
							</div>
						</div>
					</div>
				</div>

				<div class="list-listrowd" v-show="true">
					<div class="list-listrowda">
						<div class="list-listrowdaa" style="font-size: 20px;">热门国家签证</div>
						<!-- <div class="list-listrowdab">更多</div> -->
					</div>
					<div class="list-listimg marg-but" v-for="(item,index) in HotVisaList" :key="index" @click="bindGetVisaListByCode(item)">
						
						<div class="list-listimga">
							<img :src="item.CountryImg" class="list-listimgb">
						</div>
						<div class="list-listtext">
							
							<div class="ft16">{{item.CountryName}}</div>
							<div class="list-listtextb">
								<div class="list-priceaa">¥</div>
								<div class="list-priceab">{{item.MinPrice}}</div>
								<span style="margin-left: 3px;">起</span>
							</div>
						</div>
					</div>
					<!-- <div class="list-scheme">
						
						<div class="list-listrowdaa">目的地相关旅行方案</div>
						<div class="list-listrowdab">更多</div>
					</div>
					<div class="list-schemea" v-for="item in [1,2,3,4,5]" :key="item">
						<div class="iconfont icon-mudedi list-schemeb"></div>
						<div class="list-schemec">美国3天2晚游玩旅行方案</div>
					</div> -->
				</div>


			</div>
		</div>
	</div>
</template>

<script>
	var util = require('@/utils/util.js');
	//接口
	var destApi = require('@/api/destApi.js');
	var visaApi = require('@/api/visaApi.js');
	var yqfApi = require('@/api/yqfApi.js');
	var that;

	export default {
		data() {
			return {
				CountryCode: 'CA',
				CountryName:'',
				VisaList: null,
				HotVisaList:null,
				DestinationCountryVal: ''
			}
		},
		created() {
			that = this;
			var query = that.$route.query;
			console.log(query);

			if (!util.isNullOrEmpty(query.CountryCode)) {
				that.CountryCode = query.CountryCode;
			}
			GetFrontVisaList();
			GetHotVisaList();
		},
		methods: {
			//搜索国家
			queryCitySearch(queryString, cb) {
				var parm = {
					keyword: queryString,
					isCountry: true
				};
				destApi.GetCityList(parm, function(result) {
					// console.log(result);;
					if (result.code == 0) {
						var allCityList = result.data.allCityList;
						cb(allCityList);
						// console.log(that.restaurants);
					} else {
						cb([]);
					}
				});
			
				// cb(results);
			},
			handleSelectCity(item) {
				console.log(item);
				that.DestinationCountryVal = item.value;
				that.CountryCode = item.code;
				
				that.$router.push({
					path: '/Visa/list',
					query: {
						CountryCode: that.CountryCode
					}
				})
				GetFrontVisaList();
			},
			bindSearch(e){
				that.$router.push({
					path: '/Visa/list',
					query: {
						CountryCode: that.CountryCode
					}
				})
				GetFrontVisaList();
			},
			//跳转详情
			bindJumpDetail: function(e) {	
				console.log(e);
				// that.list=index
				that.$router.push({
					path: '/Visa/detail',
					query: {
						GoodCode: e.GoodCode
					}
				})
			},
			//
			bindGetVisaListByCode: function(e) {
				that.$router.push({
					path: '/Visa/list',
					query: {
						GoodCode: e.CountryCode
					}
				})
				console.log(e);
				that.CountryCode=e.CountryCode;
				GetFrontVisaList();
			}
		}
	}

	function GetFrontVisaList() {
		var parm = {
			Continent: '', //洲代码
			IATACityCode: '', //城市代码
			Country: that.CountryCode, //国家代码
			VisaType: '', //签证类型
		};
		visaApi.GetFrontVisaList(parm, function(result) {
			if (result.code == 0) {
				that.VisaList = result.data.VisaList;
				// that.CountryName=that.VisaList[0].CountryName;
			}
		})
	}
	//获取热门签证
	function GetHotVisaList() {
		var parm = {
			Continent: '', //洲代码
		};
		visaApi.GetHotVisaList(parm, function(result) {
			if (result.code == 0) {
				that.HotVisaList = result.data.HotVisaList;				
			}
		})
	}
</script>

<style lang="scss">
	@import '../../style/visa/list.scss';
</style>
